<template>
    <div class="row">
            <span class="col-sx-12 col-sm-12 col-md-12 d-sm-block d-md-block d-sx-block d-lg-none questionListBtn" v-if="selectedquestion" @click="openNav()">&#9776; Questions</span>
        <div id="mySidenav" class="questionList col-lg-4 col-xl-3 col-md-12 col-sx-12 col-sm-12" :class="{'sidenav':selectedquestion}">
            <div class="questionList col-sx-12 col-sm-12 col-md-12 d-sm-block d-sx-block d-md-block d-lg-none pb-3 pt-3 mobileQuestTitle" >
                <a v-if="selectedquestion" class="closebtn" @click="closeNav()">&times;</a>
            <h4>Re-Tell Lecture
                    <b-icon  shift-v="1" scale="1" icon="mic-fill" style="padding-left:2px;"></b-icon>
                </h4>
            </div>
            <app-IntervalList ref="intervalList" :questionLists="questionLists" :questionId="'lecture_id'"/>
        </div>
        <div class="questonhelp col-lg-8 col-xl-9 d-none d-lg-block" v-if="!selectedquestion">
            <h4 class="text-center mb-4 fancy"><span>Re-Tell Lecture</span></h4>
            <h5 class="mb-1">Introduction</h5>
            <p class="para mb-2">You will hear a lecture. After listening to the lecture, in 10 seconds, you'll have to speak into the microphone and retell what you have just heard from the lecture in your own words. You will have 40 seconds to give your response.</p>
            <h5 class="mb-1">Skills assessed</h5>
            <p class="heading mb-2">Listening and speaking</p>
            <h5 class="mb-1">Number of questions</h5>
            <p class="para mb-2"> 1 - 2 </p>
            <template v-if="user_type == 'premium'">
            <h5 class="mb-1">KEY POINTS</h5>
            <ul class="ml-5">
                <li>The lecture will start automatically. Sometimes the lecture is accompanied by an image or video. Once the audio is over, you’ll have 10 seconds to prepare yourself. After 10 seconds, you'll hear a short tone. Start speaking immediately after the tone.</li>
                <li>Don't speak before the microphone starts because your voice won’t be recorded. Speak clearly, and don’t make any hurry. You’ll be able to record your answer only once.</li>
                <li>Finish speaking before the progress bar reaches the end. The word “Recording” changes to “Completed”.</li>
                <li>You will be judged for listening, speaking, oral fluency, pronunciation and content.</li>
                <li>Noting down the keywords or phrases will help you recalling the lecture.</li>
            </ul>
            </template>
        </div>
        <div class="questiondesc col-lg-8 col-xl-9 col-md-12 col-sm-12 col-sx-12" :class="{'col-12':(questId != 0)}" v-if="selectedquestion">
            <b-overlay class="fixedHeight" :show="show" rounded="sm" >
            <h4>Re-tell Lecture
                <b-icon  shift-v="1" scale="1" icon="mic-fill" style="padding-left:2px;"></b-icon>
            </h4>
            <p class="questHeading">You will hear a lecture. After listening to the lecture, in 10 seconds, please speak into the microphone and retell what you have just heard from the lecture in your own words. You will have 40 seconds to give your response.</p>
            
            <div class="row mb-2">
            <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 titleDiv">
                <div class="prepare" ><p>{{title}} </p><app-Timer ref="timer"/></div>
            </div>
            <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 text-right pr-0" >
            <app-QuestionStatus ref="QuestionResetStatus" :selectedQuestion="selectedquestion" :question_id="selectedquestion.lecture_id" :idName="'lecture_id'" :tableName="'tblReTellLecture'"/>
            </div>
            </div>
            <div class="audio-container"><audio id="questionRec" :src="questionrecording" controls /></div>
            <p class="recordMessage">{{recordMessage}}</p>
            <div class="record-settings">
              <audio-recorder
                    :mic-failed="micCheck"
                    :before-recording="beforeRecording"
                    :after-recording="afterRecording"
                    :pause-recording="pauseRecording"
                    :src=null
                    :time=".666"
                    :class="{'disabledRecording':!recordingAllow}"
                    />
            </div>
        
           <div class="recorded-audio">
              <div v-for="(record, index) in recordings" :key="index" class="row recorded-item">
                <div class="audio-container col-12 left"><audio :src="record.src" controls /></div>
                <div class="col-12"><p style="padding-left:0px!important">AI Scoring is available after submission.</p></div>
              </div>
            </div>
            </b-overlay>
            <div class="row">
                <div class="col-lg-12 col-xl-6 col-md-12 text-left col-sm-12 questBtnDiv mt-5 mb-2">
                    <b-button class="mr-2 pl-4 pr-4" :disabled="!this.recordStop || checkAutoNext" variant="outline-info" @click="showAnswerV9Marking()">Submit</b-button>
                    <!-- <b-button class="mr-2 pl-4 pr-4" variant="outline-info" @click="showAnswerNew()">Submit</b-button> -->
                    <b-button :disabled="checkAutoNext" variant="outline-warning" class="mr-2 pl-4 pr-4" @click="redoQuest()">Re-do</b-button>
                    <b-button :disabled="apiResults ==null || checkAutoNext" variant="outline-success" class="mr-2 pl-4 pr-4" v-b-modal.result-Modal>
                        <b-spinner v-if="resultStatus=='In-Process'" small type="grow"></b-spinner>
                        {{resultStatus}}
                    </b-button>
                    <toggle-button :width="75" :height="28" v-model="checkAnswerFlag" :value="false" :color="{checked: '#104e94', unchecked: '#e9ecef', disabled: '#CCCCCC'}" :speed="500" :sync="true" :labels="{checked: 'Answer', unchecked: 'Answer'}"/>
                    <span  > | 
                  <toggle-button :width="100" :height="28" v-model="checkAutoNext" :value="false" :color="{checked: '#368f5b', unchecked: '#cae8d6', disabled: '#CCCCCC'}" :speed="500" :sync="true" :labels="{checked: 'Auto-Next', unchecked: 'Manual-Next'}"/>
                </span>
                </div>
                <div class="col-6 text-right mt-3 pl-0 d-none d-xl-block mt-5">
                    <div class="row">
                        <div class="col-2 p-0">
                             <b-button :disabled="currentQuestion <= 1" @click="$refs.intervalList.changeQuestion('prev')" variant="outline-info" class="mr-2 prevQuestion pl-3 pr-3">Prev</b-button>
                        </div>
                        <div class="col-8 p-0">
                            <b-input-group>
                                <b-input-group-prepend>
                                    <b-icon shift-v="1.2" scale="1.3" icon="search"></b-icon>
                                </b-input-group-prepend>
                                <b-form-input v-model="searchKey" type="search" placeholder="Question title"></b-form-input>
                                <b-input-group-append>
                                <b-button @click="searchQuestion()" variant="outline-secondary">Search</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </div>
                        <div class="col-2 p-0 text-left">
                             <!-- <b-button :disabled="currentQuestion <= 1" @click="$refs.intervalList.changeQuestion('prev')" class="mr-2 prevQuestion pl-3 pr-3">Prev Quest</b-button> -->
                        <b-button @click="$refs.intervalList.changeQuestion('next')" variant="outline-info" class="ml-2 nextQuestion pl-3 pr-3">Next</b-button>
                        </div>
                    </div>
                </div>
                <div class="col-12 text-center mt-4" v-if="checkAnswerFlag">
                <h4 class="decorated" style="text-transform:capitalize;"><span>Answer</span></h4>
                    <div class="col-12 mb-2 mt-2 results text-left" >
                    <h5 style="text-transform:capitalize;">Transcript:- </h5>
                    <p style="display:block;padding-bottom: 0px !important;">{{actualAnswer}}</p>
                    </div>
                </div>
            </div>
            <b-modal id="result-Modal"  ref="result-Modal" size="lg" centered hide-footer title="AI Result">
         <b-container class="resultModel" >
               <app-ResultPresent :apiResults="apiResults"/>
         </b-container>
         <b-button v-if="!checkAutoNext" class="mt-3" variant="outline-dark" left @click="$bvModal.hide('result-Modal')">Close Me</b-button>
         <b-button v-if="checkAutoNext" class="mt-3" variant="outline-dark" left >Disappear in {{ closeCounter }} seconds</b-button>
       </b-modal>
       <b-modal ref="search-Result" size="lg" scrollable centered hide-footer title="Search Result" ok-only ok-title="Cancel" ok-variant="secondary">
             <b-container v-if="searchKey!='' | searchQuestionList!=null"><app-IntervalList name="search" :popup="true" :parentFunctionCalling="getQuestionDetail" :questionLists="searchQuestionList" :questionId="'lecture_id'"/></b-container>
        </b-modal>
        </div>
        <div class="col-12 mt-5 d-none d-lg-block" v-if="selectedquestion!=null">
            <app-DiscussionTab ref="discussionTab" :question_id="currentQuestion" :tableName="'tblUserRepeatLecture'"/>
        </div>
    </div>
</template>
<script>
import { mapActions,mapGetters } from 'vuex'
import Timer from '../reuseable/timer'
//import blobToBase64 from 'blob-to-base64'
import $ from "jquery";
import IntervalList from '../reuseable/intervalList'
import QuestionStatus from '../reuseable/questStatus'
import ResultPresent from '../reuseable/resultPresent'
import DiscussionTab from '../reuseable/discussionTab'
import {bus} from '../../main'
//import LocalStorageService from '../../store/LocalStorageService';
const sdk = require("microsoft-cognitiveservices-speech-sdk");
export default {
    components:{
        'app-Timer':Timer,
        'app-IntervalList': IntervalList,
        'app-ResultPresent':ResultPresent,
        'app-QuestionStatus':QuestionStatus,
        'app-DiscussionTab':DiscussionTab
    },
    data(){
        return{
            questionLists:{
                type: Object,
                required: true
            },
            show:false,
            selectedquestion: null,
            recordings: [],
            questionrecording: null,
            selectedTime: 0,
            timeLeft: '00:00',
            startFlag:false,
            title:'Prepare',
            userAnswerResponse:null,
            userAnswer:{
                user_id:0,
                lecture_id:0,
                recording:null,
                transcript:'',
                mock_test_id:0,
                user_result:''
            },
            recordStop:false,
            actualAnswer:'',
            checkAnswerFlag:false,
            contentMarks:0,
            user_type:'Free',
            recordingAllow:false,
            currentQuestion:0,
            recordMessage:'Click to Start',
            recordingBlob:null,
            resultStatus:'AI-Result',
            apiResults:null,
            searchKey:'',
            searchQuestionList:null,
            currentUser:null,
            microsoftTranscript:'',
            closeCounter:5,
            closeTime:null,
            checkAutoNext:false
        }
    },
    methods:{
        ...mapActions({
            questionservice: 'speakmodule/fetchQuestion',
            saveAnswer: 'speakmodule/saveAnswer',
            getTranscripty:'speakmodule/getTranscripty',
            generateFileUpload:'speakmodule/generateFileUpload',
            fetchUserUsage:'auth/saveDashBoardData',
        }),
        ...mapGetters({
            getQuestionList: 'speakmodule/questions',
            answerResponse: 'speakmodule/AnswerResponse',
            userDetail: 'auth/user',
            GetUserUsageResponse:'auth/getDashBoardResponse'
        }),
        openNav(){
            document.getElementById("mySidenav").style.width = "100%";
        },
        closeNav(){
            document.getElementById("mySidenav").style.width = "0";
        },
        searchQuestion(){
            const key=this.searchKey.trim().toLowerCase()
            const searchedQuestion= this.questionLists.filter((item)=>{
                return item.title.toLowerCase().includes(key)
            });
            this.searchKey = '';
            this.$refs.timer.pauseTime()
            ////console.log(searchedQuestion)
            if(searchedQuestion.length>0){
                if(searchedQuestion.length>1){
                   //console.log(searchedQuestion)
                   this.searchQuestionList=searchedQuestion
                   this.$refs['search-Result'].show()
                }
                else{
                    //console.log(searchedQuestion)
                    return this.getQuestionDetail(searchedQuestion[0].graph_id)
                }
            }
            else{
                return this.$alert("No record found with the given title", "Search Result");
            }
        },
        updateSelectQuestionStatus(question,id){
            ////console.log(this.selectedquestion)
            this.selectedquestion = question
            const currectQuestionIndex=this.questionLists.findIndex(element=>
            element.lecture_id==id)
            this.questionLists[currectQuestionIndex]=this.selectedquestion
            ////console.log(question)
        },
        getQuestionDetail(questionId){
             if(questionId==0) return this.selectedquestion=null
            if(this.$refs['search-Result']!=undefined)
            if(this.$refs['search-Result'].isVisible) this.$refs['search-Result'].hide()
            this.currentQuestion=questionId
            this.recordingBlob=null
            this.resultStatus='AI-Result'
            this.show=false
            ////console.log(questionId)
            this.actualAnswer = ''
            this.checkAnswerFlag = false
            this.recordingAllow = false
            this.recordMessage='Click to Start'
            this.contentMarks = 0
            this.userAnswer.lecture_id = questionId;
            this.selectedquestion = this.questionLists.filter(function (item) {
                    return item.lecture_id == questionId;
                })[0];
            if(this.$refs.QuestionResetStatus)
            this.$refs.QuestionResetStatus.resetQuestionDetails(this.selectedquestion,questionId)
            this.actualAnswer = this.selectedquestion.transcript
            //this.questionservice('configurelecture/blobId='+questionId).then(()=>{
                var binary = this.selectedquestion.blb //this.getQuestionList().data.blb;
                //console.log('Retell Lecture')
                //console.log(this.getQuestionList().data.blb)
                this.questionrecording = binary;
                //console.log(this.questionrecording)
                this.recordings = []
                this.startFlag = false
                this.userAnswerResponse =''
                this.recordStop= false
                this.$nextTick(function(){
                    this.$refs.discussionTab.updateQuestionId(questionId)
                    this.$refs.timer.setTime(3)
                    this.title = 'Prepare'
                $('.ar-recorder__stop').click()
                });
            //});    
        },
        userUsage(){
        var data={
            user_id: this.currentUser.user_id,
            url:'users/userUsageCount'
          }
          this.fetchUserUsage(data).then(()=>{
            ////console.log(this.GetUserUsageResponse())
           this.currentUser.speaking_Counter= this.GetUserUsageResponse().data.speakingCount
            //this.$ls.remove('PackageType')
          })
        },
        checkUserAccess(){
            const user =this.currentUser
            if(user.user_type == 'Free' & user.speaking_Counter>=5)
            {
                return false
            }
            else if(user.user_type == 'standard' & user.speaking_Counter>=10)
            {
                return false
            }
            else{
                this.currentUser.speaking_Counter++
                return true
            }
        },
        wait(ms){
            var start = new Date().getTime();
            var end = start;
            while(end < start + ms) {
                end = new Date().getTime();
            }
        },
        showAnswer(){
            // this.actualAnswer = this.selectedquestion.transcript
            // this.checkAnswerFlag = true
            try
            {

            if(!this.checkUserAccess())
                {
                    return this.$alert(
                        "Please upgrade your plan for further practice your today limit is over",
                        "Warning",
                        "warning",
                        {
                            confirmButtonText: "Cannot Proceed"
                        })
                }
            this.resultStatus='In-Process'
            this.show=true
            const fileName='UserRetelllecture--u'+this.userAnswer.user_id+'_q'+this.selectedquestion.lecture_id+"_track"+Date.now()+'.wav'
            var file = new File([this.recordingBlob], fileName);
            //this.recordedFile = file
            var fd = new FormData()
            fd.append('files', file,fileName)
            var userdata =this.$ls.get('user')
            this.userAnswer.user_id = userdata.user_id
            var filePath= 'audio/UserRetelllecture/'+fileName.replace('--','')
             this.userAnswer.recording = filePath
             
            this.generateFileUpload(fd).then(()=>{
                //console.log(this.answerResponse())
                        if(this.answerResponse()=="Success"){
                            this.wait(7000);
                            if(this.userAnswerResponse == '') this.wait(5000)
                            var param={
                                user_audio_file: filePath.replace('audio/',''),
                                text: this.userAnswerResponse,
                                user_id: this.userAnswer.user_id,
                                quest:'u'+this.userAnswer.user_id+'/q'+this.selectedquestion.lecture_id,
                                url:'speechace/transcript'
                            }
                            //console.log(param)
                            this.getTranscripty(param).then(()=>{
                                if(this.answerResponse().data.success)
                                {
                                    if(this.answerResponse().data.result.status!='success'){
                                        this.resultStatus='No-Result'
                                        this.show=false
                                        return this.$alert('No recording found, Please retry.')
                                        
                                    }
                                    const res=this.userAnswer.transcript=this.answerResponse().data.result
                                    const textArray=this.selectedquestion.transcript.split(' ')
                                    //const correctWords=res.text_score.fluency.overall_metrics.correct_word_count
                                    //const totalWords=res.text_score.fluency.overall_metrics.word_count
                                    var content=0;
                                    //as the content must be from 90
                                    //content = Math.round((correctWords/totalWords)*100)-10
                                    var array=this.selectedquestion.transcript.split(' ')
                                    let correctWords=0
                                    for (let index = 0; index < array.length; index++) {
                                        const element = array[index];
                                        if(this.userAnswerResponse.search(element)>-1) correctWords++
                                    }
                                    // array.forEach(element => {
                                    //     if(this.userAnswerResponse.seach(element)>-1) correctWords++
                                    // });
                                    if(correctWords>=18) content=90
                                    else if(correctWords>=13) content=70+correctWords
                                    else if(correctWords>=11)content=60+correctWords
                                    else if(correctWords>=10) content=50+correctWords
                                    else if(correctWords>=8)content=35
                                    else if(correctWords>=5)content=25
                                    else content=0
                                    this.resultStatus='AI-Result Ready' 
                                    this.show=false
                                    this.apiResults={
                                        transcript:'',//this.selectedquestion.transcript,
                                        quality_Score:res.text_score.quality_score,
                                        total_Words:res.text_score.fluency.overall_metrics.word_count,
                                        correct_Words:res.text_score.fluency.overall_metrics.correct_word_count,
                                        vocab:res.text_score.fluency.overall_metrics.ielts_subscore.vocab,
                                        pte_Estimate:res.text_score.fluency.overall_metrics.pte_estimate,
                                        word_Score_List:res.text_score.word_score_list,
                                        content_Score:content,
                                        textArray:textArray,
                                        text:this.userAnswerResponse,
                                        src:filePath,
                                        type:'all'
                                    }
                                    //console.log(this.apiResults)
                                    var proScore =(this.apiResults.quality_Score>10)?this.apiResults.quality_Score-10:this.apiResults.quality_Score
                                    
                                    var overAllScore =Math.round((this.apiResults.pte_Estimate+proScore+this.apiResults.content_Score)/3)
                                    //this.$refs['result-Modal'].show()
                                    this.userAnswer.user_result='Your Score: '+overAllScore+'/90'
                                    //this.$refs['result-Modal'].show()
                                    this.userAnswer.url='userlecturerecording/createlecturerecording'
                                    this.userAnswer.mock_test_id=0
                                    //this.userAnswer.transcript=this.answerResponse().data.result
                                    this.saveAnswer(this.userAnswer).then(()=>{
                                            //console.log(this.answerResponse().data)
                                            //vm.userAnswerResponse = vm.answerResponse().data
                                        })
                                }
                                //vm.userAnswerResponse = vm.answerResponse().data
                            })
                        }
                    })
                    }
            catch(error){
                this.show=false
            }

        },
        showAnswerV9(){
            // this.actualAnswer = this.selectedquestion.transcript
            // this.checkAnswerFlag = true
            try
            {

            if(!this.checkUserAccess())
                {
                    return this.$alert(
                        "Please upgrade your plan for further practice your today limit is over",
                        "Warning",
                        "warning",
                        {
                            confirmButtonText: "Cannot Proceed"
                        })
                }
            this.resultStatus='In-Process'
            this.show=true
            const fileName='UserRetelllecture--u'+this.userAnswer.user_id+'_q'+this.selectedquestion.lecture_id+"_track"+Date.now()+'.wav'
            var file = new File([this.recordingBlob], fileName);
            //this.recordedFile = file
            var fd = new FormData()
            fd.append('files', file,fileName)
            var userdata =this.$ls.get('user')
            this.userAnswer.user_id = userdata.user_id
            var filePath= 'audio/UserRetelllecture/'+fileName.replace('--','')
             this.userAnswer.recording = filePath
             
            this.generateFileUpload(fd).then(()=>{
                //console.log(this.answerResponse())
                        if(this.answerResponse()=="Success"){
                            this.wait(7000);
                            if(this.userAnswerResponse == '') this.wait(5000)
                            var param={
                                user_audio_file: filePath.replace('audio/',''),
                                text: this.userAnswerResponse,
                                user_id: this.userAnswer.user_id,
                                quest:'u'+this.userAnswer.user_id+'/q'+this.selectedquestion.lecture_id,
                                url:'speechace/transcriptNew'
                            }
                            //console.log(param)
                            this.getTranscripty(param).then(()=>{
                                if(this.answerResponse().data.success)
                                {
                                    if(this.answerResponse().data.result.status!='success'){
                                        this.resultStatus='No-Result'
                                        this.show=false
                                        return this.$alert('No recording found, Please retry.')
                                        
                                    }
                                    const res=this.userAnswer.transcript=this.answerResponse().data.result
                                    const textArray=this.selectedquestion.transcript.split(' ')
                                    //const correctWords=res.text_score.fluency.overall_metrics.correct_word_count
                                    //const totalWords=res.text_score.fluency.overall_metrics.word_count
                                    var content=0;
                                    //as the content must be from 90
                                    //content = Math.round((correctWords/totalWords)*100)-10
                                    var array=this.selectedquestion.transcript.split(' ')
                                    // let correctWords=0
                                    // for (let index = 0; index < array.length; index++) {
                                    //     const element = array[index];
                                    //     if(this.userAnswerResponse.search(element)>-1) correctWords++
                                    // }
                                    // array.forEach(element => {
                                    //     if(this.userAnswerResponse.seach(element)>-1) correctWords++
                                    // });
                                    var conculsionWords=['finally', 'in a word', 'in brief', 'briefly', 'in conclusion','conclusion', 'in the end', 'in the final analysis', 'on the whole'
            , 'thus', 'to conclude', 'to summarize', 'in sum', 'to sum up', 'in summary','As Can Be Seen','After All','By And Large'
            ,'Generally Speaking','In Fact','To Sum Up','In The Final Analysis','On The Whole','All Things Considered'
            ,'In Any Event','As Shown Above','In Short','In Either Case','In The Long Run','In Brief','All In All'
            ,'Given These Points','In Essence','As Has Been Noted','In A Word','On Balance','For The Most Part'
            ,'Altogether', 'Obviously', 'Overall', 'Ultimately', 'Ordinarily', 'Definitely', 'and Usually',
            'as can be seen','generally speaking','all things considered','as shown above','in the long run','given these points'
            ,'as has been noted','for the most part','As discussed above','topic','lecture','keyword','one','two','descriptive','speaker','hear'];

                                    const correctWords=res.text_score.fluency.overall_metrics.correct_word_count
                                    const totalWords=res.text_score.fluency.overall_metrics.word_count
                                    
                                    if(correctWords>=18) content=90
                                    else if(correctWords>=13) content=70+correctWords
                                    else if(correctWords>=11)content=60+correctWords
                                    else if(correctWords>=10) content=50+correctWords
                                    else if(correctWords>=8)content=35
                                    else if(correctWords>=5)content=25
                                    else content=0
                                    this.resultStatus='AI-Result Ready' 
                                    this.show=false
                                    this.apiResults={
                                        transcript:'',//this.selectedquestion.transcript,
                                        // quality_Score:res.text_score.quality_score,
                                        // total_Words:res.text_score.fluency.overall_metrics.word_count,
                                        // correct_Words:res.text_score.fluency.overall_metrics.correct_word_count,
                                        // vocab:res.text_score.fluency.overall_metrics.ielts_subscore.vocab,
                                        // pte_Estimate:res.text_score.fluency.overall_metrics.pte_estimate,
                                        // word_Score_List:res.text_score.word_score_list,

                                        quality_Score:res.text_score.pte_score.overall,
                                        total_Words:res.text_score.fluency.overall_metrics.word_count,
                                        correct_Words:res.text_score.fluency.overall_metrics.correct_word_count,
                                        vocab:res.text_score.pte_score.vocab,
                                        pte_Estimate:res.text_score.pte_score.fluency,
                                        word_Score_List:res.text_score.word_score_list,
                                        
                                        content_Score:content,
                                        textArray:textArray,
                                        text:this.userAnswerResponse,
                                        src:filePath,
                                        type:'all'
                                    }
                                    //console.log(this.apiResults)
                                    var proScore =(this.apiResults.quality_Score>10)?this.apiResults.quality_Score-10:this.apiResults.quality_Score
                                    
                                    var overAllScore =Math.round((this.apiResults.pte_Estimate+proScore+this.apiResults.content_Score)/3)
                                    //this.$refs['result-Modal'].show()
                                    this.userAnswer.user_result='Your Score: '+overAllScore+'/90'
                                    //this.$refs['result-Modal'].show()
                                    this.userAnswer.url='userlecturerecording/createlecturerecording'
                                    this.userAnswer.mock_test_id=0
                                    //this.userAnswer.transcript=this.answerResponse().data.result
                                    this.saveAnswer(this.userAnswer).then(()=>{
                                            //console.log(this.answerResponse().data)
                                            //vm.userAnswerResponse = vm.answerResponse().data
                                        })
                                }
                                //vm.userAnswerResponse = vm.answerResponse().data
                            })
                        }
                    })
                    }
            catch(error){
                this.show=false
            }

        },
        showAnswerV9Marking(){
            // this.actualAnswer = this.selectedquestion.transcript
            // this.checkAnswerFlag = true
            try
            {

            if(!this.checkUserAccess())
                {
                    this.checkAnswerFlag=false;
                    return this.$alert(
                        "Please upgrade your plan for further practice your today limit is over",
                        "Warning",
                        "warning",
                        {
                            confirmButtonText: "Cannot Proceed"
                        })
                }
            this.resultStatus='In-Process'
            this.show=true
            const fileName='UserRetelllecture--u'+this.userAnswer.user_id+'_q'+this.selectedquestion.lecture_id+"_track"+Date.now()+'.wav'
            var file = new File([this.recordingBlob], fileName);
            //this.recordedFile = file
            var fd = new FormData()
            fd.append('files', file,fileName)
            var userdata =this.$ls.get('user')
            this.userAnswer.user_id = userdata.user_id
            var filePath= 'audio/UserRetelllecture/'+fileName.replace('--','')
             this.userAnswer.recording = filePath
             
            this.generateFileUpload(fd).then(()=>{
                //console.log(this.answerResponse())
                        if(this.answerResponse()=="Success"){
                            if(this.userAnswerResponse == '') this.wait(5000)
                            if(this.userAnswerResponse == ''){
                                this.resultStatus='No-Result'
                                this.show=false
                                return this.$alert('No voice found in submitted recording, Please retry.')
                            }
                            var param={
                                user_audio_file: filePath.replace('audio/',''),
                                text: this.userAnswerResponse,
                                user_id: this.userAnswer.user_id,
                                quest:'u'+this.userAnswer.user_id+'/q'+this.selectedquestion.lecture_id,
                                url:'speechace/transcriptNewMarking',
                                questType:'ReTellLecture',
                                keyWords:[],
                                questionTranscript:this.selectedquestion.transcript
                            }
                            //console.log(param)
                            this.getTranscripty(param).then(()=>{
                                if(this.answerResponse().data==undefined){
                                    this.resultStatus='No-Result'
                                        this.show=false
                                        return this.$alert('No voice found in submitted recording, Please retry.')
                                }
                                else if(this.answerResponse().data.success)
                                {
                                    if(this.answerResponse().data.result.status!='success'){
                                        this.resultStatus='No-Result'
                                        this.show=false
                                        return this.$alert('No recording found, Please retry.')
                                        
                                    }
                                    const res=this.userAnswer.transcript=this.answerResponse().data.result
                                    //const textArray=this.selectedquestion.transcript.split(' ');
                                    console.log(this.answerResponse());
                                    const markingResult=this.answerResponse().data.apiResults;
                                    //const correctWords=res.text_score.fluency.overall_metrics.correct_word_count
                                    //const totalWords=res.text_score.fluency.overall_metrics.word_count
                                    //var content=0;
                                    //as the content must be from 90
                                    //content = Math.round((correctWords/totalWords)*100)-10
                                    //var array=this.selectedquestion.transcript.split(' ')
                                    // let correctWords=0
                                    // for (let index = 0; index < array.length; index++) {
                                    //     const element = array[index];
                                    //     if(this.userAnswerResponse.search(element)>-1) correctWords++
                                    // }
                                    // array.forEach(element => {
                                    //     if(this.userAnswerResponse.seach(element)>-1) correctWords++
                                    // });
            //                         var conculsionWords=['finally', 'in a word', 'in brief', 'briefly', 'in conclusion','conclusion', 'in the end', 'in the final analysis', 'on the whole'
            // , 'thus', 'to conclude', 'to summarize', 'in sum', 'to sum up', 'in summary','As Can Be Seen','After All','By And Large'
            // ,'Generally Speaking','In Fact','To Sum Up','In The Final Analysis','On The Whole','All Things Considered'
            // ,'In Any Event','As Shown Above','In Short','In Either Case','In The Long Run','In Brief','All In All'
            // ,'Given These Points','In Essence','As Has Been Noted','In A Word','On Balance','For The Most Part'
            // ,'Altogether', 'Obviously', 'Overall', 'Ultimately', 'Ordinarily', 'Definitely', 'and Usually',
            // 'as can be seen','generally speaking','all things considered','as shown above','in the long run','given these points'
            // ,'as has been noted','for the most part','As discussed above','topic','lecture','keyword','one','two','descriptive','speaker','hear'];

            //                         const correctWords=res.text_score.fluency.overall_metrics.correct_word_count
            //                         const totalWords=res.text_score.fluency.overall_metrics.word_count
                                    
            //                         if(correctWords>=18) content=90
            //                         else if(correctWords>=13) content=70+correctWords
            //                         else if(correctWords>=11)content=60+correctWords
            //                         else if(correctWords>=10) content=50+correctWords
            //                         else if(correctWords>=8)content=35
            //                         else if(correctWords>=5)content=25
            //                         else content=0
                                    
            //                         this.apiResults={
            //                             transcript:'',
            //                             quality_Score:res.text_score.pte_score.overall,
            //                             total_Words:res.text_score.fluency.overall_metrics.word_count,
            //                             correct_Words:res.text_score.fluency.overall_metrics.correct_word_count,
            //                             vocab:res.text_score.pte_score.vocab,
            //                             pte_Estimate:res.text_score.pte_score.fluency,
            //                             word_Score_List:res.text_score.word_score_list,
                                        
            //                             content_Score:content,
            //                             textArray:textArray,
            //                             text:this.userAnswerResponse,
            //                             src:filePath,
            //                             type:'all'
            //                         }
                                    
            //                         var proScore =(this.apiResults.quality_Score>10)?this.apiResults.quality_Score-10:this.apiResults.quality_Score
                                    
            //                         var overAllScore =Math.round((this.apiResults.pte_Estimate+proScore+this.apiResults.content_Score)/3)
                                    var overAllScore =markingResult.overAllScore; 
                                    this.apiResults=markingResult;
                                    //this.$refs['result-Modal'].show()
                                    this.userAnswer.user_result='Your Score: '+overAllScore+'/90'
                                    this.resultStatus='AI-Result Ready' 
                                    this.show=false
                                    if(this.checkAutoNext) {
                                        this.closeTime=null;
                                        this.closeCounter=5
                                        this.$refs['result-Modal'].show();
                                        this.startTime(); 
                                    }
                                    //this.$refs['result-Modal'].show()
                                    this.userAnswer.url='userlecturerecording/createlecturerecording'
                                    this.userAnswer.mock_test_id=0
                                    //this.userAnswer.transcript=this.answerResponse().data.result
                                    this.saveAnswer(this.userAnswer).then(()=>{
                                            //console.log(this.answerResponse().data)
                                            //vm.userAnswerResponse = vm.answerResponse().data
                                        })
                                }
                                //vm.userAnswerResponse = vm.answerResponse().data
                            })
                        }
                    })
                    }
            catch(error){
                this.show=false
            }

        },
        showAnswerNew(){
            // this.actualAnswer = this.selectedquestion.transcript
            // this.checkAnswerFlag = true
            try
            {

            if(!this.checkUserAccess())
                {
                    return this.$alert(
                        "Please upgrade your plan for further practice your today limit is over",
                        "Warning",
                        "warning",
                        {
                            confirmButtonText: "Cannot Proceed"
                        })
                }
            this.resultStatus='In-Process'
            this.show=true
            const fileName='UserRetelllecture--u'+this.userAnswer.user_id+'_q'+this.selectedquestion.lecture_id+"_track"+Date.now()+'.wav'
            var file = new File([this.recordingBlob], fileName);
            //this.recordedFile = file
            var fd = new FormData()
            fd.append('files', file,fileName)
            var userdata =this.$ls.get('user')
            this.userAnswer.user_id = userdata.user_id
            var filePath= 'audio/UserRetelllecture/'+fileName.replace('--','')
             this.userAnswer.recording = filePath
             
            this.generateFileUpload(fd).then(()=>{
                //console.log(this.answerResponse())
                        if(this.answerResponse()=="Success"){
                            this.wait(7000);
                            if(this.userAnswerResponse == '') this.wait(5000)
                            var param={
                                user_audio_file: filePath.replace('audio/',''),
                                text: this.userAnswerResponse,
                                user_id: this.userAnswer.user_id,
                                quest:'u'+this.userAnswer.user_id+'/q'+this.selectedquestion.lecture_id,
                                url:'deepgram/deepgramTranscript'
                            }
                            //console.log(param)
                            this.getTranscripty(param).then(()=>{
                                var deepGramTranscriptJson=this.answerResponse().data.result.results.channels[0].alternatives[0];
                               //console.log(this.answerResponse().data.success)
                                
                                if(this.answerResponse().data.success==1)
                                {
                                    // if(this.answerResponse().data.result.status!='success'){
                                    //     this.resultStatus='No-Result'
                                    //     this.show=false
                                    //     return this.$alert('No recording found, Please retry.')
                                        
                                    // }
                                    //this.microsoftTranscript=(this.microsoftTranscript=="")?deepGramTranscriptJson.transcript:this.microsoftTranscript;
                                    const res=this.userAnswer.transcript=deepGramTranscriptJson//this.answerResponse().data.result
                                    var listWords=deepGramTranscriptJson.words;
                                    //debugger
                                    //console.log(listWords);
                                    var contCorrectWords=0;
                                    var contWrongWords=0;
                                    var pteEstimate=0;
                                    var questionText=this.selectedquestion.transcript;
                                    var actualIndex=0;
                                    $.each(listWords.reverse(),(index,item)=>{
                                    if(questionText.indexOf(item.punctuated_word)!=-1) {
                                        contCorrectWords++;
                                        pteEstimate+=item.confidence;
                                    }
                                    else {
                                        contWrongWords++;
                                        listWords[actualIndex].confidence=0;
                                        pteEstimate-=item.confidence*1;
                                    }
                                    actualIndex++;
                                    });
                                    //console.log(listWords);
                                    const textArray=deepGramTranscriptJson.transcript.split(' ')
                                    //const correctWords=(contCorrectWords-contWrongWords>1)?(contCorrectWords-contWrongWords):0 ; //res.text_score.fluency.overall_metrics.correct_word_count
                                    const totalWords=textArray.length 
                                    //const correctWords=res.text_score.fluency.overall_metrics.correct_word_count
                                    //const totalWords=res.text_score.fluency.overall_metrics.word_count
                                    var content=0;
                                    //as the content must be from 90
                                    //content = Math.round((correctWords/totalWords)*100)-10
                                    var array=this.selectedquestion.transcript.split(' ')
                                    let correctWords=0
                                    for (let index = 0; index < array.length; index++) {
                                        const element = array[index];
                                        if(deepGramTranscriptJson.transcript.search(element)>-1) correctWords++
                                    }
                                    // array.forEach(element => {
                                    //     if(this.userAnswerResponse.seach(element)>-1) correctWords++
                                    // });
                                    if(correctWords>=18) content=90
                                    else if(correctWords>=13) content=70+correctWords
                                    else if(correctWords>=11)content=60+correctWords
                                    else if(correctWords>=10) content=50+correctWords
                                    else if(correctWords>=8)content=35
                                    else if(correctWords>=5)content=25
                                    else content=0
                                    pteEstimate=Math.round((pteEstimate/totalWords)*100)-10;
                                    this.resultStatus='AI-Result Ready' 
                                    this.show=false
                                    this.apiResults={
                                        transcript:'',//this.selectedquestion.transcript,
                                        quality_Score:(contCorrectWords/(contCorrectWords+contWrongWords)*100).toFixed(0),//res.text_score.quality_score,
                                        total_Words:totalWords,//res.text_score.fluency.overall_metrics.word_count,
                                        correct_Words:correctWords,//res.text_score.fluency.overall_metrics.correct_word_count,
                                        vocab:pteEstimate,//res.text_score.fluency.overall_metrics.ielts_subscore.vocab,
                                        pte_Estimate:pteEstimate,//res.text_score.fluency.overall_metrics.pte_estimate,
                                        word_Score_List:listWords.reverse(),//res.text_score.word_score_list,
                                        content_Score:content,
                                        textArray:textArray,
                                        text:this.userAnswerResponse,
                                        src:filePath,
                                        type:'all'
                                    }
                                    //console.log(this.apiResults)
                                    var proScore =(this.apiResults.quality_Score>10)?this.apiResults.quality_Score-10:this.apiResults.quality_Score
                                    
                                    var overAllScore =Math.round((this.apiResults.pte_Estimate+proScore+this.apiResults.content_Score)/3)
                                    //this.$refs['result-Modal'].show()
                                    this.userAnswer.user_result='Your Score: '+overAllScore+'/90'
                                    //this.$refs['result-Modal'].show()
                                    this.userAnswer.url='userlecturerecording/createlecturerecording'
                                    this.userAnswer.mock_test_id=0
                                    //this.userAnswer.transcript=this.answerResponse().data.result
                                    this.saveAnswer(this.userAnswer).then(()=>{
                                            //console.log(this.answerResponse().data)
                                            //vm.userAnswerResponse = vm.answerResponse().data
                                        })
                                }
                                else{
                                    this.resultStatus='No-Result'
                                        this.show=false
                                        return this.$alert('No recording found, Please retry.')
                                }
                                //vm.userAnswerResponse = vm.answerResponse().data
                            })
                        }
                    })
                    }
            catch(error){
                this.show=false
            }

        },
        startTime()
        {
            //var tt;
            if(this.closeCounter==5 && this.closeTime==null){
                this.closeTime=setInterval(()=>{this.startTime()},1000);
                console.log(this.closeCounter)
            }
            else if(this.closeCounter == 0) {
                console.log(this.closeCounter)
                clearInterval(this.closeTime);
                this.$refs['result-Modal'].hide();
                this.$refs.intervalList.changeQuestion('next');
            } else {
                console.log(this.closeCounter)
            this.closeCounter--;
            }
        },
        removeRecord (index) {
            this.recordings.splice(index, 1)
        },
        timerEnd(){
            //////console.log(this.$refs)
            //this.$refs.recording.stream(new MediaStream)
           // this.$listeners.onStream()
           
           if(!this.startFlag)
           {
               var aud = document.getElementById("questionRec");
               aud.play()
               this.startFlag = true
               this.title = 'Prepare'
               var self = this
                aud.onended = function() {
                self.$refs.timer.setTime(10)
                self.startFlag = true
            }
            
           }
           else{
                $('.ar-icon.ar-icon__lg').click()
           } 
           //aud.play()
            //alert('Timer')
        },
         redoQuest(){
            this.getQuestionDetail(this.selectedquestion.lecture_id)
        },
        playBeep(){
            var sound = document.getElementById("beepAudio");
            sound.playbackRate = 0.5;
            sound.play()
        },
        pauseRecording(){
            $('.ar-recorder__stop').click()
        },
        beforeRecording (msg) {
            this.microsoftTranscript=''
            this.playBeep()
            this.checkAnswerFlag = false
            this.recordingAllow = true
            //console.log('Before Record: ', msg)
            if(this.$refs.timer!=undefined)this.$refs.timer.setTime(0)
            this.title = 'Time'
            this.userAnswerResponse =''
            this.recordStop= false
            this.recordMessage='Click to Stop'
            $('.ar-recorder__stop').addClass('upFront')
            this.fromMic()
        },
        afterRecording(msg){
            this.recordMessage='Done'
            this.recognizer.stopContinuousRecognitionAsync();
            //this.startFlag = false
            ////console.log('After Record: ', msg)
            this.recordings=[]
            this.recordings.push({
            src: window.URL.createObjectURL(msg.blob)
            })
            this.$refs.timer.pauseTime()
            this.recordStop = true
            this.userAnswer.transcript=this.userAnswerResponse
            this.recordingBlob=msg.blob
            $('.ar-recorder__stop').removeClass('upFront')
            if(this.checkAutoNext) this.showAnswerV9Marking();

        },
        micCheck(msg){
            alert(msg)
        },
        // fromMic() {
        //     const speechConfig = sdk.SpeechConfig.fromSubscription(process.env.VUE_APP_MICRO_API, process.env.VUE_APP_MICRO_SERVICEREGION);
        //     let audioConfig = sdk.AudioConfig.fromDefaultMicrophoneInput();
        //     speechConfig.setProperty(sdk.PropertyId.SpeechServiceConnection_InitialSilenceTimeoutMs, "3000"); // 10000ms
        //     let recognizer = new sdk.SpeechRecognizer(speechConfig, audioConfig);
    
        //         ////console.log('Speak into your microphone.');
        //         recognizer.recognizeOnceAsync(result => {
        //             ////console.log(result)
        //         ////console.log(`RECOGNIZED: Text=${result.text}`);
        //         if(result.text !='undefined' | result.text !=undefined)
        //         this.userAnswerResponse+=result.text;

        //         if(!this.recordStop) this.fromMic()
        //         else recognizer.close()
        //     },
        //     err => {
        //         recognizer.close()
        //         console.trace("err - " + err);
        //     });
        // },
        checkRecordingPause(){
            if(this.recordMessage!='Done')
                {
                let myVar = setInterval(function() {
                    
                    if(this.microsoftTranscript!==this.microsoftTranscript & this.recordMessage!='Done')
                    {
                        //console.log('Inside Interval')
                        this.checkRecordingPause()
                    }
                    else{
                        //console.log('Interval stop')
                        clearInterval(myVar);
                        $('.ar-recorder__stop').click()
                    }
                }, 3000);
            }
        },
        fromMic() {
            //console.log('In Mic')
            const speechConfig = sdk.SpeechConfig.fromSubscription(process.env.VUE_APP_MICRO_API, process.env.VUE_APP_MICRO_SERVICEREGION);
            let audioConfig = sdk.AudioConfig.fromDefaultMicrophoneInput();
            //speechConfig.setProperty(sdk.PropertyId.SpeechServiceConnection_InitialSilenceTimeoutMs, "3000");
            this.recognizer = new sdk.SpeechRecognizer(speechConfig, audioConfig);
            const phraseList = sdk.PhraseListGrammar.fromRecognizer(this.recognizer);
            phraseList.addPhrase(this.selectedquestion.transcript);
            //console.log('Speak into your microphone.');


            this.recognizer.startContinuousRecognitionAsync();

            this.recognizer.recognizing = (s, e) => {
                if(e!=null)
                {
                     //this.microsoftTranscript =e.result.text;
                    //console.log(`RECOGNIZING sub : Text=${e.result.text}`);
                }
            };

            this.recognizer.recognized = (s, e) => {
                ////console.log(e.result)
                    if (e.result.reason == 3) {
                     this.microsoftTranscript=this.userAnswerResponse +=e.result.text;
                     //this.checkRecordingPause()
                        //console.log(`RECOGNIZED final: Text=${e.result.text}`);
                    }
                    else if (e.result.reason == 0) {
                        this.pauseRecording()
                        //console.log("NOMATCH: Speech could not be recognized.");
                    }
            };

            this.recognizer.canceled = (s, e) => {
                //console.log(`CANCELED: Reason=${e.reason}`);

                if (e.reason == 0) {
                    //console.log(`"CANCELED: ErrorCode=${e.errorCode}`);
                    //console.log(`"CANCELED: ErrorDetails=${e.errorDetails}`);
                    //console.log("CANCELED: Did you update the subscription info?");
                }

                this.recognizer.stopContinuousRecognitionAsync();
            };

            this.recognizer.sessionStopped = () => {
                ////console.log(s+e)
                //console.log("\n    Session stopped event.");
                this.recognizer.stopContinuousRecognitionAsync();
            };
            
        },
        _arrayBufferToBase64( buffer ) {
            var binary = '';
            var bytes = new Uint8Array( buffer );
            var len = bytes.byteLength;
            for (var i = 0; i < len; i++) {
                binary += String.fromCharCode( bytes[ i ] );
            }
            return window.btoa( binary );
        }
    },
    beforeMount(){
        bus.$emit('checkMicPermission');
        this.questionLists=(this.$ls.get('tblReTellLecture')!=null)?JSON.parse(this.$ls.get('tblReTellLecture')):null;
            console.log(this.questionLists)
            if(this.questionLists!=null){
                var userdata =this.$ls.get('user')
                this.userAnswer.user_id = userdata.user_id
                this.user_type = userdata.user_type
                this.currentUser=userdata
                this.userUsage()
            }
            else{
        this.questionservice('configurelecture/getalllectures').then(()=>{
            this.questionLists = this.getQuestionList().data
            //var userdata = LocalStorageService.getUser()
            var userdata =this.$ls.get('user')
            this.userAnswer.user_id = userdata.user_id
            this.user_type = userdata.user_type
            this.currentUser=userdata
            this.userUsage()
            ////console.log(this.questionLists)
        });
    }
    }
}
</script>
<style scoped>

</style>